import React from "react";
import { BodyText, Title } from "@src/components/new-nessie";
import { Link } from "gatsby";
import Container from "@src/components/Container";
import { StyledHeading, TitleBanner } from "./PrivacyLayout";
import styled from "@emotion/styled";

type DocumentList = {
  title: string;
  documents: {
    slug: string;
    publicationDate: string;
  }[];
}[];

type LegalHistoryProps = {
  pageContext: {
    docList: {
      childrens: DocumentList;
      tos: DocumentList;
      main: DocumentList;
      website: DocumentList;
      "premium-tos": DocumentList;
    };
    localeStrings: any;
  };
};

const LinkSyyled = styled(Link)`
  font-size: 16px;
`;

const LegalHistoryLayout: React.FC<LegalHistoryProps> = ({ pageContext }) => {
  return (
    <>
      <TitleBanner>
        <Title>Past Versions of Legal Documents </Title>
      </TitleBanner>
      <Container mb={100}>
        {Object.keys(pageContext.docList).map((key, index) => {
          const group = pageContext.docList[key];
          const docs = group.documents.slice(1);
          if (docs.length === 0) return null;

          return (
            <div key={index}>
              <StyledHeading>{group.title}</StyledHeading>
              <ul>
                {docs.map((document, index) => (
                  <BodyText as="li" key={index}>
                    <LinkSyyled to={`/legal-history/${document.slug}`}>
                      {new Date(document.publicationDate).toLocaleDateString()}
                    </LinkSyyled>
                  </BodyText>
                ))}
              </ul>
            </div>
          );
        })}
      </Container>
    </>
  );
};

export default LegalHistoryLayout;
